@use '../../App.scss';

.lang-selector{
    button{
        padding-left: 0;
        padding-right: 0;

        img{
            height: 3rem;
            margin-right: none !important;
        }
    }
}