@use '../../App.scss';

.footer-style {
    >.footer-wrapper {
        background-color: App.$primary;
        display: flex;
        margin-top: auto;
        padding: 0 4rem 0 4rem;
        
        >div{
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }
}

