.carousel{
  display: flex;
  flex-direction: column;
  overflow: scroll;
  
  .carouselImage{
    width: 6rem;
    height: auto;
    object-fit: scale-down;

    &:hover{
      filter: brightness(.9);
      cursor: pointer;
    }

    &.selected{
      filter: brightness(.7);
    }
  }
}