    .rule-selector{
        display: flex;
        :first-child{
            margin-left: auto;
        }
        :last-child{
            margin-right: 1rem;
        }
    }
    .image-styling{
        max-width: 800px;
        width: 100%;
    }