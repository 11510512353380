@use './styling/variables';
@forward './styling/variables';


#root{
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;

  font-family: "Source Sans Pro", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}


html,body,#root{
  height: 100%;
}

#home{
  img{
    height: 50px;
  }
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.app-wrapper{
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
  z-index: 999;

}