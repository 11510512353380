$primary: #c3d6e0;
$secondary: #6c757d;
$green: rgb(43, 214, 85);

:root {
    --bs-primary: $primary;
    --bs-secondary: $secondary;
    --bs-success: $green;
    // --bs-info: #0dcaf0;
    // --bs-warning: #ffc107;
    // --bs-danger: #dc3545;
    // --bs-light: #f8f9fa;
    // --bs-dark: #212529;
}