@use '../../App.scss';

.product-tile{
    padding: 1rem;
    
    img{
        border-radius: 10px;
        object-fit: cover;

    }
    h5 {
        text-align: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    p{
        text-align: center;
        color: App.$secondary;
    }

        &:hover {
            filter: brightness(.9);
            cursor: pointer;
        }
}