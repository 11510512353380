@use "../../App.scss";

.product-dialog-style {
  height: 90vh;
  .modal-content {
    height: 100%;
  }

  .modal-body-style {
    overflow: scroll;
  }

  .selectable-images-wrapper {
    height: 100%;
    padding-right: 1rem;
  }

  .selected-image-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .selected-image-style {
      width: 100%;
      max-height: 50%;
      object-fit: contain;
      overflow-y: scroll;
      margin-bottom: 1rem;
    }

    .product-description-wrapper {
        flex-grow: 1;
        padding: 0.25rem;
        border-radius: 5px;
    }

    .product-info-tile{
      margin: 0.125rem;
      flex-direction: column;
      display: flex;
      border-radius: .25rem;
      padding: .5rem;

      :nth-child(1){
        font-weight: 600;
      }
      :nth-child(2) {
      }
    }
  }
}

*[hidden] {
  display: none !important;
}
